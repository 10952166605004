import { camelCase } from 'scule'

/**
 * Recursively converts the keys of an object or array from kebab-case to camelCase.
 *
 * Since we're binding props directly to the `style` attribute, we need to convert potential nested
 * properties that may be defined as kebab-case in MDC to camelCase in order
 * to properly bind them to the style attribute.
 *
 * @template T - The prop type being passed in to return.
 * @param {T} prop - The object or array whose keys are to be converted from kebab-case to camelCase.
 * @returns {T} - A new object or array with kebab-case keys converted to camelCase.
 */
export default function convertKebabCasePropertiesToCamelCase<T>(prop: T): T {
  if (Array.isArray(prop)) {
    return prop.map((item: any) => convertKebabCasePropertiesToCamelCase(item)) as T
  } else if (prop !== null && typeof prop === 'object') {
    return Object.keys(prop).reduce((acc, key) => {
      // Only convert the key if it's in kebab-case
      const camelCaseKey = /-/.test(key) ? camelCase(key) : key
      acc[camelCaseKey] = convertKebabCasePropertiesToCamelCase((prop as Record<string, any>)[key])
      return acc
    }, {} as Record<string, any>) as T
  }
  return prop
}
